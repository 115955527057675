import axios from 'axios'

export const CASES_URL = process.env.REACT_APP_API_URL + '/comments'

// CREATE =>  POST: add a new comment to the server
export function createComment(comment_data) {
	if (comment_data.authorizationData)
		return axios.post(
			process.env.REACT_APP_API_URL + '/createComment',
			comment_data,
		)
	else return axios.post(CASES_URL, comment_data)
}

// READ
export function getAllComments(case_id, content) {
	return axios.get(`${CASES_URL}/${content}/${case_id}`)
}

export function getCommentById(commentId) {
	return axios.get(`${CASES_URL}/${commentId}`)
}

export function getOneComment(commentId) {
	return axios.get(`${CASES_URL}/${commentId}/getOne`)
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findComments(queryParams = {}) {
	return axios.get(`${CASES_URL}?queryParams=${JSON.stringify(queryParams)}`)
}

// UPDATE => PUT: update the comment on the server
export function updateComment(comment_data) {
	return axios.put(`${CASES_URL}`, comment_data)
}

// UPDATE Status
export function updateStatusForComments(ids, status) {
	return axios.post(`${CASES_URL}/updateStatusForComments`, {
		ids,
		status,
	})
}

// DELETE => delete the comment from the server
export function deleteComment(commentId) {
	return axios.delete(`${CASES_URL}/${commentId}`)
}

// DELETE Comments by ids
export function deleteComments(ids) {
	return axios.post(`${CASES_URL}/deleteComments`, { ids })
}

// READ ALL ADMIN FILTERS
export function generateFilter() {
	return axios.get(`${CASES_URL}/generateFilter`)
}
