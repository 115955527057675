import * as requestFromServer from './commentsCrud'
import { commentsSlice, callTypes } from './commentsSlice'

const { actions } = commentsSlice

export const getAllComments = (case_id) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	try {
		const response = await requestFromServer.getAllComments(case_id, 'case')
		if (response.status !== 200) throw response
		const { totalCount, entities } = response.data.data
		dispatch(actions.commentsFetched({ totalCount, entities }))
	} catch ({ response }) {
		let error = response.data.message || "Can't get comments"
		dispatch(actions.catchError({ error, callType: callTypes.list }))
	}
}

export const fetchComments = (queryParams) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	try {
		const response = await requestFromServer.findComments(queryParams)
		if (response.status !== 200) throw response
		const { totalCount, entities } = response.data.data
		dispatch(actions.commentsFetched({ totalCount, entities }))
	} catch ({ response }) {
		let error = response.data.message || "Can't get comments"
		dispatch(actions.catchError({ error, callType: callTypes.list }))
	}
}

export const fetchComment = (id) => async (dispatch) => {
	if (!id) {
		return dispatch(actions.commentFetched({ commentForEdit: undefined }))
	}
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		const response = await requestFromServer.getCommentById(id)
		if (response.status !== 200) throw response
		const { data } = response.data
		dispatch(actions.commentFetched({ commentForEdit: data }))
	} catch ({ response }) {
		let error = response.data.message || "Can't find comment"
		dispatch(actions.catchError({ error, callType: callTypes.list }))
	}
}

export const deleteComment = (id) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		const response = await requestFromServer.deleteComment(id)
		if (response.status !== 200) throw response
		dispatch(actions.commentDeleted({ id }))
	} catch ({ response }) {
		let error = response.data.message || "Can't delete comment"
		dispatch(actions.catchError({ error, callType: callTypes.action }))
	}
}

export const createComment = (commentForCreation) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		const response = await requestFromServer.createComment(commentForCreation)
		if (response.status !== 200) throw response
		const { data } = response.data
		dispatch(actions.commentCreated({ data }))
	} catch ({ response }) {
		let error = response.data.message || "Can't create comment"
		dispatch(actions.catchError({ error, callType: callTypes.action }))
	}
}

export const updateComment = (_comment) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		const response = await requestFromServer.updateComment(_comment)
		if (response.status !== 200) throw response
		dispatch(actions.commentUpdated({ _comment }))
	} catch ({ response }) {
		let error = response.data.message || "Can't update comment"
		dispatch(actions.catchError({ error, callType: callTypes.action }))
	}
}

export const updateCommentStatus = (id, status) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		const response = await requestFromServer.updateComment({ id, status })
		if (response.status !== 200) throw response
		dispatch(actions.commentStatusUpdated({ status }))
	} catch ({ response }) {
		let error =
			response.status === 422
				? response.data.data[0].msg
				: response.data.message || "Can't update comment"
		dispatch(actions.catchError({ error, callType: callTypes.action }))
	}
}
