// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { Input } from '../../../_metronic/_partials/controls'
import ProgressHelpers from '../../../_metronic/_helpers/ProgressHelpers'

// Validation schema
const SettingEditSchema = Yup.object().shape({
	GUEST_CUSTOMER_ID: Yup.string(),
})
export function SettingEditForm({ saveSetting, settings, loading, success }) {
	return (
		<>
			<Formik
				enableReinitialize={true}
				initialValues={settings}
				validationSchema={SettingEditSchema}
				onSubmit={(values) => {
					saveSetting(values)
				}}
			>
				{({ handleSubmit }) => (
					<>
						<div className='overlay overlay-block cursor-default'>
							<Form className='form form-label-right'>
								<div className='form-group row'>
									{/* First Name */}
									{Object.keys(settings).map((item, k) => (
										<div className='col-lg-6 my-3' key={k}>
											{item === 'BUSINESS_SLUG' ? (
												<>
													<b>Business Slug:</b>{' '}
													<a
														href={
															window.location.origin +
															'/business/' +
															settings.BUSINESS_SLUG +
															'/new'
														}
													>
														{window.location.origin +
															'/business/' +
															settings.BUSINESS_SLUG +
															'/new'}
													</a>
												</>
											) : (
												<Field
													name={item}
													component={Input}
													placeholder={item}
													label={item}
												/>
											)}
										</div>
									))}
								</div>
							</Form>
						</div>
						<ProgressHelpers
							loading={loading}
							success={success}
							handleSubmit={handleSubmit}
							text={'Update'}
						/>
					</>
				)}
			</Formik>
		</>
	)
}
