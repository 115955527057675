/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'
import { checkIsActive } from '../../../../_helpers'

export function AsideMenuList({ layoutProps }) {
	const { role_level } = useSelector(
		(state) => state.auth.user.role_details,
		shallowEqual
	)
	const location = useLocation()
	const getMenuItemActive = (url, hasSubmenu = false) => {
		return checkIsActive(location, url)
			? ` ${!hasSubmenu &&
					'menu-item-active'} menu-item-open menu-item-not-hightlighted`
			: ''
	}

	return (
		<>
			{/* begin::Menu Nav */}
			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				{/*begin::1 Level*/}
				{/* <li
					className={`menu-item ${getMenuItemActive('/dashboard', false)}`}
					aria-haspopup='true'
				>
					<NavLink className='menu-link' to='/dashboard'>
						<span className='svg-icon menu-icon'>
							<DashboardIcon />
						</span>
						<span className='menu-text'>Dashboard</span>
					</NavLink>
				</li> */}
				{/*end::1 Level*/}

				{/* Admin Settings */}
				{/* begin::section */}
				{/* <li className='menu-section '>
					<h4 className='menu-text'>Application</h4>
					<i className='menu-icon flaticon-more-v2'></i>
				</li> */}
				{/* end:: section */}

				{/*begin::1 Level*/}
				<li
					className={`menu-item ${getMenuItemActive('/cases', false)}`}
					aria-haspopup='true'
				>
					<NavLink className='menu-link' to='/cases'>
						<span className='svg-icon menu-icon'>
							<span className='material-icons'>help</span>
						</span>
						<span className='menu-text'>Cases</span>
					</NavLink>
				</li>
				{/*end::1 Level*/}

				{role_level < 8 && (
					<>
						{/* Admin Settings */}
						{/* begin::section */}
						<li className='menu-section '>
							<h4 className='menu-text'>Admin Settings</h4>
							<i className='menu-icon flaticon-more-v2'></i>
						</li>
						{/* end:: section */}

						{/*begin::1 Level*/}
						<li
							className={`menu-item menu-item-submenu ${getMenuItemActive(
								'/solutions',
								true
							)}`}
							aria-haspopup='true'
							data-menu-toggle='hover'
						>
							<NavLink className='menu-link menu-toggle' to='/solutions'>
								<span className='svg-icon menu-icon'>
									<span className='material-icons'>emoji_objects</span>
								</span>
								<span className='menu-text'>Solutions</span>
								<i className='menu-arrow' />
							</NavLink>
							<div className='menu-submenu '>
								<i className='menu-arrow' />
								<ul className='menu-subnav'>
									<li
										className='menu-item  menu-item-parent'
										aria-haspopup='true'
									>
										<span className='menu-link'>
											<span className='menu-text'>Solutions</span>
										</span>
									</li>

									{/*begin::2 Level*/}
									<li
										className={`menu-item ${getMenuItemActive('/categories')}`}
										aria-haspopup='true'
									>
										<NavLink className='menu-link' to='/categories'>
											<i className='menu-bullet menu-bullet-dot'>
												<span />
											</i>
											<span className='menu-text'>Category</span>
										</NavLink>
									</li>
									{/*begin::2 Level*/}
									<li
										className={`menu-item ${getMenuItemActive('/solutions')}`}
										aria-haspopup='true'
									>
										<NavLink className='menu-link' to='/solutions'>
											<i className='menu-bullet menu-bullet-dot'>
												<span />
											</i>
											<span className='menu-text'>Solutions</span>
										</NavLink>
									</li>
									{/*end::2 Level*/}

									{/*end::2 Level*/}
								</ul>
							</div>
						</li>
						{/*end::1 Level*/}

						<li
							className={`menu-item ${getMenuItemActive('/settings', false)}`}
							aria-haspopup='true'
						>
							<NavLink className='menu-link' to='/settings'>
								<span className='svg-icon menu-icon'>
									<span className='material-icons'>settings</span>
								</span>
								<span className='menu-text'>Settings</span>
							</NavLink>
						</li>
					</>
				)}
			</ul>
			{/* end::Menu Nav */}
		</>
	)
}
