import React, { useState } from 'react'
// import S3 from "react-aws-s3";
import { Formik, Form, Field } from 'formik'
import { Input, Select } from '../../../../../../_metronic/_partials/controls'
import * as Yup from 'yup'
import { toAbsoluteUrl } from '../../../../../../_metronic/_helpers'
import SVG from 'react-inlinesvg'
import { Modal } from 'react-bootstrap'
import { WizardControl } from './wizard_control'
import './wizard-5.css'
import 'jquery'
import ReactSummernote from 'react-summernote'
import 'react-summernote/dist/react-summernote.css' // import styles
import 'bootstrap'
import { Upload } from '../../../../../components/Upload'

const CaseEditSchema = Yup.object().shape({
	subject: Yup.string()
		.required('Case Subject is required')
		.max(25, 'Maximum 25 characters'),
	description: Yup.string().required('Description is required'),
	department_id: Yup.string().required('Department is required'),
	assigned_to: Yup.string(),
	contact_name: Yup.string().required('Contact Name is required'),
	type: Yup.string(),
	request_type: Yup.string(),
	contact_email: Yup.string()
		.email('Invalid email')
		.required('Contact Email is required'),
	priority: Yup.string(),
})

export function CaseEditForm({
	saveCase,
	_case,
	actionsLoading,
	onHide,
	loading,
	isError,
	setisError,
	users,
	departments,
	business_id,
}) {
	const [disableSubmit, setdisableSubmit] = useState(false)
	return (
		<>
			<Formik
				enableReinitialize={true}
				initialValues={_case}
				validationSchema={CaseEditSchema}
				onSubmit={(values) => {
					saveCase(values)
				}}
			>
				{({ handleSubmit, setFieldValue, values, handleChange }) => (
					<>
						<div className='card card-custom'>
							{/* <!--begin::Card Body--> */}
							<div className='card-body p-0'>
								{/* <!--begin::Wizard 5--> */}
								<div
									className='wizard wizard-5 d-flex flex-column flex-lg-row flex-row-fluid'
									id='kt_wizard'
								>
									{/* <!--begin::Aside--> */}
									<div className='wizard-aside bg-white d-flex flex-column flex-row-auto w-100 w-lg-200px w-xl-300px w-xxl-400px'>
										{/* <!--begin::Aside Top--> */}
										<div className='d-flex flex-column-fluid flex-column px-xxl-10 px-10'>
											{/* <!--begin: Wizard Nav--> */}
											<div className='wizard-nav d-flex d-flex justify-content-center pt-10 pt-lg-20 pb-5'>
												{/* <!--begin::Wizard Steps--> */}
												<div className='wizard-steps'>
													{/* <!--begin::Wizard Step 1 Nav--> */}
													<div
														className='wizard-step'
														data-wizard-type='step'
														data-wizard-state='current'
													>
														<div className='wizard-wrapper'>
															<div className='wizard-icon'>
																<i className='wizard-check ki ki-check'></i>
																<span className='wizard-number'>1</span>
															</div>
															<div className='wizard-label'>
																<h3 className='wizard-title'>Case Details</h3>
																<div className='wizard-desc'>
																	Enter your Case Details
																</div>
															</div>
														</div>
													</div>
													{/* <!--end::Wizard Step 1 Nav--> */}
													{/* <!--begin::Wizard Step 2 Nav--> */}
													<div className='wizard-step' data-wizard-type='step'>
														<div className='wizard-wrapper'>
															<div className='wizard-icon'>
																<i className='wizard-check ki ki-check'></i>
																<span className='wizard-number'>2</span>
															</div>
															<div className='wizard-label'>
																<h3 className='wizard-title'>
																	Case Properties
																</h3>
																<div className='wizard-desc'>
																	Select Case Properties
																</div>
															</div>
														</div>
													</div>
													{/* <!--end::Wizard Step 2 Nav--> */}
													{/* <!--begin::Wizard Step 3 Nav--> */}
													<div className='wizard-step' data-wizard-type='step'>
														<div className='wizard-wrapper'>
															<div className='wizard-icon'>
																<i className='wizard-check ki ki-check'></i>
																<span className='wizard-number'>3</span>
															</div>
															<div className='wizard-label'>
																<h3 className='wizard-title'>
																	Contact Details
																</h3>
																<div className='wizard-desc'>
																	Case Contact Details
																</div>
															</div>
														</div>
													</div>
													{/* <!--end::Wizard Step 3 Nav--> */}
												</div>
												{/* <!--end::Wizard Steps--> */}
											</div>
											{/* <!--end: Wizard Nav--> */}
										</div>
										{/* <!--end::Aside Top--> */}
										{/* <!--begin::Aside Bottom--> */}
										{/* <div className="d-flex flex-row-auto bgi-no-repeat bgi-position-y-bottom bgi-position-x-center bgi-size-contain pt-2 pt-lg-5 h-350px" style={{backgroundImage: `url(${toAbsoluteUrl("/media/svg/illustrations/features.svg")})`}}></div> */}
										{/* <!--end::Aside Bottom--> */}
									</div>
									{/* <!--begin::Aside--> */}
									{/* <!--begin::Content--> */}
									<div className='wizard-content bg-gray-100 d-flex flex-column flex-row-fluid py-5 px-5 px-lg-10'>
										{/* begin::Alert */}
										{isError && (
											<div
												className='alert alert-custom alert-light-danger fade show mb-2 py-1 px-3'
												role='alert'
											>
												<div className='alert-icon'>
													<span className='svg-icon svg-icon-3x svg-icon-danger'>
														<SVG
															src={toAbsoluteUrl(
																'/media/svg/icons/Code/Info-circle.svg'
															)}
														></SVG>{' '}
													</span>
												</div>
												<div className='alert-text font-weight-bold'>
													{isError}
												</div>
												<div
													className='alert-close'
													onClick={() => setisError(false)}
												>
													<button
														type='button'
														className='close'
														data-dismiss='alert'
														aria-label='Close'
													>
														<span aria-hidden='true'>
															<i className='ki ki-close'></i>
														</span>
													</button>
												</div>
											</div>
										)}
										{/* end::Alert */}
										{/* <!--begin::Form--> */}
										<div className='d-flex justify-content-center flex-row-fluid'>
											<Modal.Body className='overlay overlay-block cursor-default'>
												{actionsLoading && (
													<div className='overlay-layer bg-transparent'>
														<div className='spinner spinner-lg spinner-success' />
													</div>
												)}
												<Form
													noValidate
													className='pb-5 w-100 w-md-550px w-lg-600px'
												>
													{/* <!--begin: Wizard Step 1--> */}
													<div
														className='pb-5'
														data-wizard-type='step-content'
														data-wizard-state='current'
													>
														{/* <!--begin::Title--> */}
														<div className='pb-5'>
															<h3 className='font-weight-bolder text-dark font-size-h2'>
																Case Details
															</h3>
														</div>
														{/* <!--begin::Title--> */}
														{/* <!--begin::Form Group--> */}
														<div className='form-group row'>
															{/* First Name */}
															<div className='col-lg-12'>
																<Field
																	name='subject'
																	component={Input}
																	placeholder='Case Subject'
																	label='Case Subject'
																/>
															</div>
														</div>
														<div className='form-group row'>
															{/* First Name */}
															<div className='col-lg-12'>
																{!values.id && (
																	<>
																		{' '}
																		<label className='form-label mb-3'>
																			Enter Case description
																		</label>
																		<ReactSummernote
																			value={values.description}
																			options={{
																				height: 150,
																				dialogsInBody: true,
																				placeholder:
																					'Type your case description here...',
																				toolbar: [
																					['style', ['style']],
																					[
																						'font',
																						['bold', 'underline', 'clear'],
																					],
																					['fontname', ['fontname']],
																					['para', ['ul', 'ol', 'paragraph']],
																					// ['table', ['table']],
																					['insert', ['link']],
																					// ['insert', ['link', 'picture', 'video']],
																					['view', ['fullscreen', 'codeview']],
																				],
																			}}
																			onChange={(e) =>
																				setFieldValue('description', e)
																			}
																		/>
																	</>
																)}
															</div>
														</div>
													</div>
													{/* <!--end: Wizard Step 1--> */}
													{/* <!--begin: Wizard Step 2--> */}
													<div className='pb-5' data-wizard-type='step-content'>
														{/* <!--begin::Title--> */}
														<div className='pb-5'>
															<h3 className='font-weight-bolder text-dark font-size-h2'>
																Case Properties
															</h3>
														</div>
														<div className='form-group row'>
															<div className='col-lg-6'>
																<div className='form-group row'>
																	<label className='col-5 col-form-label'>
																		Case Type
																	</label>
																	<div className='col-7 col-form-label'>
																		<div className='radio-list'>
																			<label className='radio'>
																				<input
																					type='radio'
																					name='type'
																					value='support'
																					checked={values.type === 'support'}
																					onChange={handleChange}
																				/>
																				<span></span>
																				Support
																			</label>
																			<label className='radio'>
																				<input
																					type='radio'
																					name='type'
																					value='request'
																					checked={values.type === 'request'}
																					onChange={handleChange}
																				/>
																				<span></span>
																				Request
																			</label>
																		</div>
																	</div>
																</div>
															</div>
															<div className='col-lg-6'>
																<div className='form-group row'>
																	<label className='col-4 col-form-label'>
																		Priority
																	</label>
																	<div className='col-8 col-form-label'>
																		<div className='radio-list'>
																			<label className='radio'>
																				<input
																					type='radio'
																					name='priority'
																					value='low'
																					checked={values.priority === 'low'}
																					onChange={handleChange}
																				/>
																				<span></span>
																				Low
																			</label>
																			<label className='radio'>
																				<input
																					type='radio'
																					name='priority'
																					value='medium'
																					checked={values.priority === 'medium'}
																					onChange={handleChange}
																				/>
																				<span></span>
																				Medium
																			</label>
																			<label className='radio'>
																				<input
																					type='radio'
																					name='priority'
																					value='high'
																					checked={values.priority === 'high'}
																					onChange={handleChange}
																				/>
																				<span></span>
																				High
																			</label>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														{values.type === 'request' && (
															<div className='col-lg-6'>
																<div className='form-group row'>
																	<label className='col-5 col-form-label'>
																		Request Type
																	</label>
																	<div className='col-7 col-form-label'>
																		<div className='radio-list'>
																			<label className='radio'>
																				<input
																					type='radio'
																					name='request_type'
																					value='issue'
																					checked={
																						values.request_type === 'issue'
																					}
																					onChange={handleChange}
																				/>
																				<span></span>
																				Issue
																			</label>
																			<label className='radio'>
																				<input
																					type='radio'
																					name='request_type'
																					checked={
																						values.request_type === 'complaint'
																					}
																					value='complaint'
																					onChange={handleChange}
																				/>
																				<span></span>
																				Complaint
																			</label>
																		</div>
																	</div>
																</div>
															</div>
														)}
														{values.id && (
															<div className='col-lg-6'>
																<div className='form-group row'>
																	<label className='col-5 col-form-label'>
																		Origin
																	</label>
																	<div className='col-7 col-form-label'>
																		<div className='radio-list'>
																			<label className='radio'>
																				<input
																					type='radio'
																					name='origin'
																					value='web'
																					checked={values.origin === 'web'}
																					onChange={handleChange}
																				/>
																				<span></span>
																				Web
																			</label>
																			<label className='radio'>
																				<input
																					type='radio'
																					name='origin'
																					checked={
																						values.request_type === 'E-email'
																					}
																					value='email'
																					onChange={handleChange}
																				/>
																				<span></span>
																				Email
																			</label>
																		</div>
																	</div>
																</div>
															</div>
														)}
														{!values.id && (
															<div className='form-group row'>
																<Upload
																	setFieldValue={setFieldValue}
																	setdisableSubmit={setdisableSubmit}
																/>
															</div>
														)}
													</div>
													{/* <!--end: Wizard Step 2--> */}
													{/* <!--begin: Wizard Step 3--> */}
													<div className='pb-5' data-wizard-type='step-content'>
														{/* <!--begin::Title--> */}
														<div className='pb-5'>
															<h3 className='font-weight-bolder text-dark font-size-h2'>
																Contact Details
															</h3>
														</div>
														<div className='form-group row'>
															<div className='col-lg-6'>
																<Field
																	name='contact_name'
																	component={Input}
																	placeholder='Contact Name'
																	label='Contact Name'
																/>
															</div>
															{/* First Name */}
															<div className='col-lg-6'>
																<Field
																	name='contact_email'
																	type='email'
																	component={Input}
																	placeholder='Contact Email'
																	label='Contact Email'
																/>
															</div>
														</div>
														<div className='form-group row'>
															{/* First Name */}
															<div className='col-lg-6'>
																<Select
																	name='department_id'
																	label='Department'
																	className='form-control'
																>
																	<option>-- Select One --</option>
																	{departments.map((department) => (
																		<option
																			key={department.id}
																			value={department.id}
																		>
																			{department.name}
																		</option>
																	))}
																</Select>
															</div>
															{/* <!--begin::Form Group--> */}
															{!business_id && (
																<div className='col-6'>
																	<Select
																		name='assigned_to'
																		label='Assign to'
																		className='form-control'
																	>
																		<option>-- Select One --</option>
																		{users
																			.filter(
																				(user) =>
																					user.department_id ===
																					Number(values.department_id)
																			)
																			.map((staff) => (
																				<option key={staff.id} value={staff.id}>
																					{staff.name}
																				</option>
																			))}
																	</Select>
																</div>
															)}
														</div>
														{/* <!--end::Form Group--> */}
													</div>
													<input type='hidden' name='id'></input>
													{/* <!--end: Wizard Step 3--> */}
													{/* <!--begin: Wizard Actions--> */}
													<div className='d-flex justify-content-between pt-3'>
														<div className='mr-2'>
															<button
																type='button'
																className='btn btn-light-primary font-weight-bolder font-size-h6 pl-6 pr-8 py-4 my-3 mr-3'
																data-wizard-type='action-prev'
															>
																<span className='svg-icon svg-icon-md mr-1'>
																	<SVG
																		src={toAbsoluteUrl(
																			'/media/svg/icons/Navigation/Left-2.svg'
																		)}
																	></SVG>{' '}
																</span>
																Previous
															</button>
														</div>
														<div>
															{/* <div className={classes.root}> */}
															{/* <div className={classes.wrapper}> */}
															<button
																type='button'
																disabled={loading}
																className='btn btn-success font-weight-bolder text-uppercase px-9 py-4'
																data-wizard-type='action-submit'
																onClick={handleSubmit}
															>
																<span>Submit</span>
																{loading && (
																	<span className='ml-3 spinner spinner-white'></span>
																)}
															</button>
															{/* </div> */}
															{/* </div> */}
															<button
																type='submit'
																disabled={disableSubmit}
																className='btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3'
																data-wizard-type='action-next'
															>
																<span>Next Step</span>
																{disableSubmit ? (
																	<span className='ml-1 mr-3 spinner spinner-white'></span>
																) : (
																	<span className='svg-icon svg-icon-md ml-1'>
																		<SVG
																			src={toAbsoluteUrl(
																				'/media/svg/icons/Navigation/Right-2.svg'
																			)}
																		></SVG>{' '}
																	</span>
																)}
															</button>
														</div>
													</div>
													{/* <!--end: Wizard Actions--> */}
												</Form>
											</Modal.Body>
											<WizardControl />
										</div>
										{/* // <!--end::Form--> */}
									</div>
									{/* // <!--end::Content--> */}
								</div>
								{/* // <!--end::Wizard 5--> */}
							</div>
							{/* // <!--end::Card Body--> */}
						</div>
					</>
				)}
			</Formik>
		</>
	)
}
