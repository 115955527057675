import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers'
import { useParams } from 'react-router'
import { CaseViewPage } from '../../../Case/pages/cases/case-view/CaseViewPage'
import { confirmCasePassword } from '../../_redux/open/openCrud'
import Swal from 'sweetalert2'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { casesSlice } from '../../../Case/_redux/cases/casesSlice'
import { commentsSlice } from '../../../Case/_redux/comments/commentsSlice'

const caseAction = casesSlice.actions
const commentAction = commentsSlice.actions
const initialValues = {
	email: '',
	password: '',
}
const LoginSchema = Yup.object().shape({
	password: Yup.string().required('Required Field'),
})
export function CaseDetails() {
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const [isAuthorized, setIsAuthorized] = useState(false)
	const { case_number } = useParams()
	const formik = useFormik({
		initialValues,
		validationSchema: LoginSchema,
		onSubmit: (values) => {
			setLoading(true)
			confirmCasePassword({ ...values, case_number }).then((response) => {
				setLoading(false)
				if (response.status === 200) {
					dispatch(
						caseAction.caseFetched({ caseForEdit: response.data.data.case })
					)
					dispatch(
						commentAction.commentsFetched({ ...response.data.data.comments })
					)
					setIsAuthorized(true)
				} else Swal.fire('Oops!!!', response.response.data.message, 'error')
			})
			// login_using_keys(values.public_key, values.private_key, values.module_id)
		},
	})
	const getInputClasses = (fieldname) => {
		if (formik.touched[fieldname] && formik.errors[fieldname]) {
			return 'is-invalid'
		}

		if (formik.touched[fieldname] && !formik.errors[fieldname]) {
			return 'is-valid'
		}

		return ''
	}
	return (
		<div className='d-flex flex-column flex-root'>
			{/*begin::Login*/}
			<div
				className='login login-4 login-signin-on d-flex flex-row-fluid'
				id='kt_login'
			>
				<div
					className='d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat'
					style={{
						backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-3.jpg')}`,
					}}
				>
					<div className='login-form p-7 position-relative overflow-hidden'>
						{/*begin::Login Header*/}
						{!isAuthorized ? (
							<>
								{' '}
								<div className='d-flex flex-center mb-15'>
									<span>
										<img
											src={toAbsoluteUrl('/media/logos/singlelogin.png')}
											className='max-h-75px'
											alt=''
										/>
									</span>
								</div>
								{/*end::Login Header*/}
								{/*begin::Login Sign in form*/}
								<div className='login-signin'>
									<div className='mb-20'>
										<h3>Enter Password to access the case</h3>
										{/* <div className='text-muted font-weight-bold'>
									Enter your details to login to your account:
								</div> */}
									</div>
									<form
										className='form'
										id='kt_login_signin_form'
										onSubmit={formik.handleSubmit}
									>
										<div className='form-group mb-5'>
											<input
												className={`form-control h-auto form-control-solid py-4 px-8 ${getInputClasses(
													'email'
												)}`}
												type='email'
												placeholder='email'
												{...formik.getFieldProps('email')}
											/>
										</div>
										<div className='form-group mb-5'>
											<input
												className={`form-control h-auto form-control-solid py-4 px-8 ${getInputClasses(
													'password'
												)}`}
												type='password'
												placeholder='Password'
												{...formik.getFieldProps('password')}
											/>
										</div>
										<button
											disabled={loading}
											id='kt_login_signin_submit'
											className='btn btn-primary font-weight-bold px-9 py-4 my-3'
										>
											<span>Submit</span>
											{loading && (
												<span className='ml-3 spinner spinner-white'></span>
											)}
										</button>
									</form>
								</div>
							</>
						) : (
							<CaseViewPage
								authorizationData={{
									email: formik.values.email,
									password: formik.values.password,
								}}
							/>
						)}
						{/*end::Login Sign in form*/}
					</div>
				</div>
			</div>
			{/*end::Login*/}
		</div>
	)
}
