/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { Select } from '../../../../../../_metronic/_partials/controls'
import * as Yup from 'yup'
import Select2 from './Select2'

const CaseEditSchema = Yup.object().shape({
	reason: Yup.string().required('Reason is required'),
	solution_id: Yup.string(),
})
const init = { reason: '', solution_id: '' }

export function CaseCloseModal(props) {
	useEffect(() => {
		//get solutions
	}, [])
	return (
		<>
			<Modal
				show={props.closeCaseModal}
				onHide={() => props.setCloseCaseModal(false)}
				size='md'
				aria-labelledby='contained-modal-title-vcenter'
				centered
			>
				<Formik
					enableReinitialize={true}
					initialValues={init}
					validationSchema={CaseEditSchema}
					onSubmit={(values) => {
						const reason = values.reason
						const solution_id = values.solution_id
						props.updateStatus('closed', reason, solution_id)
					}}
				>
					{({ handleSubmit, setFieldValue }) => (
						<>
							<Modal.Header closeButton>
								<Modal.Title id='contained-modal-title-vcenter'>
									Close Case
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<Form noValidate>
									<div className='form-group'>
										<Select
											name='reason'
											label='Closed reason'
											className='form-control'
										>
											<option>-- Select One --</option>
											<option>resolved</option>
											<option>abandoned</option>
											<option>known issue</option>
										</Select>
									</div>
									<div className='form-group'>
										<Select2 setFieldValue={setFieldValue} />
									</div>
								</Form>
							</Modal.Body>
							<Modal.Footer>
								<Button onClick={() => props.setCloseCaseModal(false)}>
									Close
								</Button>
								<Button variant='success' onClick={handleSubmit}>
									Submit
								</Button>
							</Modal.Footer>
						</>
					)}
				</Formik>
			</Modal>
		</>
	)
}
