import axios from 'axios'

export const CASES_URL = process.env.REACT_APP_API_URL + '/solutions'

// CREATE =>  POST: add a new solution to the server
export function createSolution(solution) {
	return axios.post(CASES_URL, solution)
}

// READ
export function getAllSolutions() {
	return axios.get(CASES_URL)
}

export function getSolutionById(solutionId) {
	return axios.get(`${CASES_URL}/${solutionId}`)
}

export function getOneSolution(solutionId) {
	return axios.get(`${CASES_URL}/${solutionId}/getOne`)
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findSolutions(queryParams = {}) {
	return axios.get(`${CASES_URL}?queryParams=${JSON.stringify(queryParams)}`)
}

// UPDATE => PUT: update the solution on the server
export function updateSolution(solution) {
	return axios.put(`${CASES_URL}`, solution)
}

// UPDATE Status
export function updateStatusForSolutions(ids, status) {
	return axios.post(`${CASES_URL}/updateStatusForSolutions`, {
		ids,
		status,
	})
}

// DELETE => delete the solution from the server
export function deleteSolution(solutionId) {
	return axios.delete(`${CASES_URL}/${solutionId}`)
}

// DELETE Solutions by ids
export function deleteSolutions(ids) {
	return axios.post(`${CASES_URL}/deleteSolutions`, { ids })
}

// READ ALL ADMIN FILTERS
export function generateFilter() {
	return axios.get(`${CASES_URL}/generateFilter`)
}
