/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Upload } from '../../../../../components/Upload'

const MediaEditSchema = Yup.object().shape({
	file_url: Yup.string().required('File Url is required'),
	file_name: Yup.string().required('File Name is required'),
	file_type: Yup.string().required('File Type is required'),
})
const init = { file_url: '', file_name: '', file_type: '' }

export function AddFileModal(props) {
	return (
		<>
			<Modal
				show={props.addFileModal}
				onHide={() => props.setAddFileModal(false)}
				size='md'
				aria-labelledby='contained-modal-title-vcenter'
				centered
			>
				<Formik
					enableReinitialize={true}
					initialValues={init}
					validationSchema={MediaEditSchema}
					onSubmit={(values) => {
						props.addFile(values)
					}}
				>
					{({ handleSubmit, setFieldValue }) => (
						<>
							<Modal.Header closeButton>
								<Modal.Title id='contained-modal-title-vcenter'>
									Add File
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<Form noValidate>
									<div className='form-group row'>
										<Upload
											setFieldValue={setFieldValue}
											setdisableSubmit={props.setLoading}
										/>
									</div>
								</Form>
							</Modal.Body>
							<Modal.Footer>
								<Button onClick={() => props.setAddFileModal(false)}>
									Close
								</Button>
								<Button
									disabled={props.loading}
									variant='success'
									onClick={handleSubmit}
								>
									Submit
									{props.loading && (
										<span className='ml-1 mr-3 spinner spinner-white'></span>
									)}
								</Button>
							</Modal.Footer>
						</>
					)}
				</Formik>
			</Modal>
		</>
	)
}
