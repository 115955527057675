import axios from 'axios'

export const API_URL = process.env.REACT_APP_API_URL

// CREATE =>  POST: add a new case to the server
export function getBusinessId(business_slug) {
	return axios.get(API_URL + '/getBusinessId/' + business_slug)
}

export function confirmCasePassword(data) {
	return axios.post(API_URL + '/caseAuth', data)
}
