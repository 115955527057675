import { useEffect } from 'react'
import axios from 'axios'
import { format } from 'date-fns'
let cancelTokenSource = undefined

export function FileUpload(props) {
	useEffect(() => {
		if (props.cancelRequest === true) {
			cancelTokenSource.cancel('Upload canceled')
		}
	}, [props.cancelRequest])
	useEffect(() => {
		if (props.file) {
			props.setUploading(true)

			// try {
			const file = props.file.name.split('.')
			// const extension = file.pop()
			const fileName =
				format(new Date(), 'yyyyMMddHHmmss') + '-' + file.join('.')
			const fileType = props.file.type
			axios
				.post(`${process.env.REACT_APP_API_URL}/sign_s3`, {
					fileName,
					fileType,
				})
				.then((response) => {
					var returnData = response.data.data.returnData
					var signedRequest = returnData.signedRequest
					var url = returnData.url
					cancelTokenSource = axios.CancelToken.source()
					var instance = axios.create()
					delete instance.defaults.headers.common['Authorization']
					// Put the fileType in the headers for the upload
					var options = {
						headers: {
							'Content-Type': fileType,
						},
						cancelToken: cancelTokenSource.token,
						onUploadProgress: (progressEvent) =>
							props.setProgress(
								((progressEvent.loaded * 100) / progressEvent.total).toFixed(2),
							),
					}
					instance
						.put(signedRequest, props.file, options)
						.then((result) => {
							props.setFileUrl(url)
							props.setUploading(false)
						})
						.catch((error) => {
							console.log(error)
							props.setUploading(false)
							props.setUploadError(error)
						})
				})
				.catch((error) => {
					console.log(error)
					props.setUploading(false)
					props.setUploadError(error)
				})
		}
		// eslint-disable-next-line
	}, [props.file])
	return ''
}
