import axios from 'axios'

export const USERS_URL = process.env.REACT_APP_API_URL + '/users/staffs'

// CREATE =>  POST: add a new staff to the server
export function createStaff(staff) {
	return axios.post(USERS_URL, staff)
}

// READ
export function getAllStaffs() {
	return axios.get(`${USERS_URL}`)
}

export function getStaffById(staffId) {
	return axios.get(`${process.env.REACT_APP_API_URL}/users/${staffId}`)
}

export function getOneStaff(staffId) {
	return axios.get(`${USERS_URL}/${staffId}/getOne`)
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findStaffs(business_id) {
	if (business_id)
		return axios.get(
			`${process.env.REACT_APP_API_URL + '/getStaffs/' + business_id}`,
		)
	else return axios.get(`${USERS_URL}`)
}

// UPDATE => PUT: update the staff on the server
export function updateStaff(staff) {
	return axios.put(`${USERS_URL}`, staff)
}

// UPDATE Status
export function updateStatusForStaffs(ids, status) {
	return axios.post(`${USERS_URL}/updateStatusForStaffs`, {
		ids,
		status,
	})
}

// DELETE => delete the staff from the server
export function deleteStaff(staffId) {
	return axios.delete(`${USERS_URL}/${staffId}`)
}

// DELETE Staffs by ids
export function deleteStaffs(ids) {
	return axios.post(`${USERS_URL}/deleteStaffs`, { ids })
}

// READ ALL ADMIN USERS
export function getStaffs() {
	return axios.get('http://localhost:5000/getStaffs')
}

// READ ALL ADMIN FILTERS
export function generateFilter() {
	return axios.get(`${USERS_URL}/generateFilter`)
}
