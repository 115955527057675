import React, { useState, useEffect } from 'react'
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers'
import { useParams } from 'react-router'
import { CaseModal } from '../../../Case/pages/cases/case-edit-dialog/caseModal'
import { getBusinessId } from '../../_redux/open/openCrud'
import Swal from 'sweetalert2'

export function CreateCase() {
	let { business_slug } = useParams()
	const [isError, setisError] = useState(false)
	const [business_id, setBusinessId] = useState(false)
	const [success, setSuccess] = useState(false)
	const hide = () => {
		setisError(false)
	}
	useEffect(() => {
		getBusinessId(business_slug)
			.then((response) => {
				if (response.status === 200)
					setBusinessId(response.data.data.business_id)
				else Swal.fire('Oops!!!', response.response.data.message, 'error')
			})
			.catch(({ response }) => console.log(response.data.message))
	}, [business_slug])
	return (
		<div className='d-flex flex-column flex-root'>
			{/*begin::Login*/}
			<div
				className='login login-4 login-signin-on d-flex flex-row-fluid'
				id='kt_login'
			>
				<div
					className='d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat'
					style={{
						backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-3.jpg')})`,
					}}
				>
					<div className='login-form p-7 position-relative overflow-hidden'>
						{/*begin::Login Header*/}
						<div className='d-flex flex-center mb-5'>
							<span>
								<img
									src={toAbsoluteUrl('/media/logos/singlelogin.png')}
									className='max-h-50px'
									alt=''
								/>
							</span>
						</div>
						{/*end::Login Header*/}
						{/*begin::Login Sign in form*/}
						{success === true ? (
							<button
								type='button'
								onClick={() => setSuccess(false)}
								className='btn btn-primary font-weight-bolder font-size-h6'
							>
								<span>Create A New Case</span>
							</button>
						) : (
							business_id && (
								<CaseModal
									onHide={hide}
									isError={isError}
									setisError={setisError}
									business_id={business_id}
									setSuccess={setSuccess}
								/>
							)
						)}
						{/* {business_id && (
							<CaseModal
								onHide={hide}
								isError={isError}
								setisError={setisError}
								business_id={business_id}
								setSuccess={setSuccess}
							/>
						)} */}
					</div>
				</div>
			</div>
			{/*end::Login*/}
		</div>
	)
}
