import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../_redux/cases/casesActions'
import { CaseEditDialogHeader } from './CaseEditDialogHeader'
import { CaseEditForm } from './CaseEditForm'
import store from '../../../../../../redux/store'
import SnackBarHelpers from '../../../../../../_metronic/_helpers/SnackBarHelpers'
import { fetchStaffs } from '../../../../User/_redux/staffs/staffsActions'
import { createCase } from '../../../_redux/cases/casesCrud'
import Swal from 'sweetalert2'

export function CaseModal({
	id,
	isError,
	setisError,
	hide,
	business_id,
	setSuccess,
}) {
	const [snackbar, setSnackbar] = useState()
	const [loading, setLoading] = useState(false)
	const [caseForEdit, setCaseForEdit] = useState()
	const [users, setUsers] = useState([])
	const [departments, setDepartments] = useState([])

	// Cases Redux state
	const dispatch = useDispatch()
	const { actionsLoading, cases, staffs, user } = useSelector(
		(state) => ({
			actionsLoading: state.cases.actionsLoading,
			cases: state.cases.entities,
			staffs: state.staffs.entities,
			user: state.auth.user,
		}),
		shallowEqual
	)

	useEffect(() => {
		const case_for_edit = cases.find((case_data) => case_data.id === Number(id))
		setCaseForEdit(case_for_edit)
	}, [id, cases])

	// server request for saving case
	const saveCase = (case_data) => {
		setLoading(true)
		if (!id) {
			if (business_id) {
				case_data.business_id = business_id
				createCase(case_data)
					.then((response) => {
						setLoading(false)
						if (response.status === 200) {
							Swal.fire(
								'Great',
								'Case has been logged successfully, we will get back to you shortly',
								'success'
							)
							setSuccess(true)
						} else
							setSnackbar({
								status: true,
								vart: 'error',
								msg: response.data.data.message,
							})
					})
					.catch(() => {
						setSnackbar({
							status: true,
							vart: 'error',
							msg: 'Case not Created Successfully',
						})
					})
			} else {
				// server request for creating case
				dispatch(actions.createCase(case_data)).then(() => {
					setLoading(false)
					let error_state = store.getState().cases.error
					if (error_state !== undefined && error_state !== null) {
						setisError(error_state)
					} else {
						setSnackbar({
							status: true,
							vart: 'success',
							msg: 'Case Created Successfully',
						})
						hide()
					}
				})
			}
		} else {
			// server request for updating case
			dispatch(actions.updateCase(case_data)).then(() => {
				setLoading(false)
				let error_state = store.getState().cases.error
				if (error_state !== undefined && error_state !== null) {
					setisError(error_state)
				} else {
					setSnackbar({
						status: true,
						vart: 'success',
						msg: 'Case Updated Successfully',
					})
					hide()
				}
			})
		}
	}
	useEffect(() => {
		dispatch(fetchStaffs(business_id))
	}, [dispatch, business_id])
	useEffect(() => {
		if (staffs.length) {
			setUsers(staffs)
			const distinctDepartment = []
			const map = new Map()
			for (const item of staffs) {
				if (!map.has(item.department_id)) {
					map.set(item.department_id, true) // set any value to Map
					distinctDepartment.push({
						id: item.department_id,
						name: item.department_name,
					})
				}
			}
			setDepartments(distinctDepartment)
		}
	}, [staffs])
	const initCase = {
		id: undefined,
		subject: '',
		type: 'support',
		request_type: undefined,
		priority: 'low',
		origin: undefined,
		contact_name: user ? user.user_detail?.name : '',
		contact_email: user ? user.user_detail?.email : '',
		assigned_to: '',
		department_id: '',
		description: '',
	}
	return (
		<>
			<CaseEditDialogHeader id={id} />
			<CaseEditForm
				saveCase={saveCase}
				actionsLoading={actionsLoading}
				_case={caseForEdit || initCase}
				onHide={hide}
				loading={loading}
				isError={isError}
				setisError={setisError}
				users={users}
				departments={departments}
				business_id={business_id}
			/>
			{snackbar && (
				<SnackBarHelpers
					status={snackbar.status}
					vart={snackbar.vart}
					msg={snackbar.msg}
					setSnackbar={setSnackbar}
				/>
			)}
		</>
	)
}
