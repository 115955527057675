import React, { useState, useEffect } from 'react'
import { IconButton, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { FileUpload } from './FileUpload'

export function Upload(props) {
	// const [loading, setloading] = useState(false)
	const [fileUrl, setFileUrl] = useState()
	const [fileType, setFileType] = useState()
	const [fileName, setFileName] = useState()
	const [progress, setProgress] = useState(0)
	const [uploading, setUploading] = useState(false)
	const [uploadError, setUploadError] = useState()
	const [file, setFile] = useState()
	const [fileUploaded, setFileUploaded] = useState()
	const [cancelRequest, setCancelRequest] = useState()

	useEffect(() => {
		fileUrl && props.setFieldValue('file_url', fileUrl)
		fileUrl && props.setFieldValue('file_type', fileType)
		fileUrl && props.setFieldValue('file_name', fileName)
		setFileUploaded()
		// eslint-disable-next-line
	}, [fileUrl])
	useEffect(() => {
		props.setdisableSubmit(uploading)
		// eslint-disable-next-line
	}, [uploading])
	useEffect(() => {
		// console.log(uploadError)
		setFileUploaded()
	}, [uploadError])

	const handleChange = (e) => {
		const up_file = e.target.files[0]
		setFileType(e.target.files[0].type)
		setFileName(e.target.files[0].name)
		props.setdisableSubmit(true)
		setProgress(0)
		setFileUploaded(up_file)
	}
	const handleCancel = () => {
		setCancelRequest(true)
		setFileUploaded()
	}

	// Perform the upload
	const handleUpload = () => {
		setFile(fileUploaded)
	}
	return (
		<>
			<div className='py-2'>
				<input
					className='form-control form-control-solid h-auto py-5 px-6'
					onChange={handleChange}
					type='file'
					accept='file/*'
				/>
				<FileUpload
					file={file}
					setFileUrl={setFileUrl}
					setProgress={setProgress}
					setUploading={setUploading}
					setUploadError={setUploadError}
					uploading={uploading}
					cancelRequest={cancelRequest}
				/>
				{fileUploaded && uploading === false ? (
					<Button
						variant='contained'
						color='default'
						onClick={handleUpload}
						disabled={uploading}
						className='my-2'
					>
						Upload
						<CloudUploadIcon />
					</Button>
				) : (
					uploading && (
						<>
							<div className='d-flex align-items-center justify-content-between flex-wrap'>
								<div className='symbol bg-white-o-15 mr-3'></div>
								<div className='text-white m-0 flex-grow-1 mr-3 font-size-h5'>
									<div className='progress'>
										<div
											className={`progress-bar progress-bar-striped progress-bar-animated bg-${
												fileUrl ? 'success' : 'primary'
											}`}
											role='progressbar'
											style={{ width: progress + '%' }}
											aria-valuenow={progress}
											aria-valuemin='0'
											aria-valuemax='100'
										>
											{fileUrl ? 'Completed' : progress + '%'}
										</div>
									</div>
								</div>
								{uploading === true && (
									<span className=''>
										<IconButton
											color='primary'
											aria-label='Add to shopping cart'
											onClick={handleCancel}
										>
											<CloseIcon style={{ color: 'red' }} />
										</IconButton>
									</span>
								)}
							</div>
						</>
					)
				)}
				{fileUrl && (
					<div className='container text-success'>Successfully uploaded!!!</div>
				)}
			</div>
		</>
	)
}
