import React, { useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { updateSetting } from './_redux/settings/settingsCrud'
import { SettingEditForm } from './SettingEditForm'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../_metronic/_helpers'
import SnackBarHelpers from '../../../_metronic/_helpers/SnackBarHelpers'
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../_metronic/_partials/controls'
import { fetchSettings } from './_redux/settings/settingsActions'

export function Setting() {
	const [isError, setisError] = useState(false)
	const [loading, setLoading] = useState(false)
	const [success, setSuccess] = useState(false)
	const [snackbar, setSnackbar] = useState()
	const initSetting = {
		BUSINESS_SLUG: '',
		GUEST_CUSTOMER_ID: '',
	}
	const dispatch = useDispatch()
	const { settings } = useSelector(
		(state) => ({
			settings: state.settings.entities,
		}),
		shallowEqual,
	)
	useEffect(() => {
		dispatch(fetchSettings())
	}, [dispatch])
	// server request for saving setting
	const saveSetting = (setting) => {
		setSuccess(false)
		setLoading(true)
		// server request for creating setting
		updateSetting(setting)
			.then((res) => {
				setSuccess(true)
				setLoading(false)
				setSnackbar({
					status: true,
					vart: 'success',
					msg: 'Settings Updated Successfully',
				})
			})
			.catch(({ response }) => {
				setLoading(false)
				setSnackbar({ status: true, vart: 'error', msg: response.data.message })
			})
	}

	return (
		<>
			{/* begin::Alert */}
			{isError && (
				<div
					className='alert alert-custom alert-light-danger fade show mb-10'
					role='alert'
				>
					<div className='alert-icon'>
						<span className='svg-icon svg-icon-3x svg-icon-danger'>
							<SVG
								src={toAbsoluteUrl('/media/svg/icons/Code/Info-circle.svg')}
							></SVG>{' '}
						</span>
					</div>
					<div className='alert-text font-weight-bold'>{isError}</div>
					<div className='alert-close' onClick={() => setisError(false)}>
						<button
							type='button'
							className='close'
							data-dismiss='alert'
							aria-label='Close'
						>
							<span aria-hidden='true'>
								<i className='ki ki-close'></i>
							</span>
						</button>
					</div>
				</div>
			)}
			{/* end::Alert */}
			<Card>
				<CardHeader title='Update Settings' />
				<CardBody>
					<SettingEditForm
						saveSetting={saveSetting}
						settings={settings || initSetting}
						loading={loading}
						success={success}
					/>
				</CardBody>
			</Card>
			{snackbar && (
				<SnackBarHelpers
					status={snackbar.status}
					vart={snackbar.vart}
					msg={snackbar.msg}
					setSnackbar={setSnackbar}
				/>
			)}
		</>
	)
}
