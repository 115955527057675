import React, { useEffect, useState } from 'react'

import AsyncSelect from 'react-select/async'
// import { ColourOption, colourOptions } from '../data'
import { findSolutions } from '../../../../Solution/_redux/solutions/solutionsCrud'
import { initialFilter } from '../../../../Solution/pages/solutions/SolutionsUIHelpers'

const filterColors = async (inputValue) => {
	initialFilter.filter.solution_number = inputValue.toUpperCase()
	let data = []
	await findSolutions(initialFilter).then((response) => {
		if (response.status === 200) {
			data = response.data.data.entities.map((entity) => ({
				value: entity.id,
				label: entity.solution_no,
			}))
		}
	})
	return data
}

const promiseOptions = (inputValue) =>
	new Promise((resolve) => {
		resolve(filterColors(inputValue))
	})
export default function Select2({ setFieldValue }) {
	const [defaultOptions, setDefaultOptions] = useState()
	const handleInputChange = (newValue) => {
		const inputValue = newValue
		return inputValue
	}
	useEffect(() => {
		findSolutions(initialFilter).then((response) => {
			if (response.status === 200) {
				const data = response.data.data.entities.map((entity) => ({
					value: entity.id,
					label: entity.solution_no,
				}))
				setDefaultOptions(data)
			}
		})
	}, [])
	return (
		<AsyncSelect
			cacheOptions
			loadOptions={promiseOptions}
			defaultOptions={defaultOptions}
			onInputChange={handleInputChange}
			onChange={(e) => setFieldValue('solution_id', e.value)}
		/>
	)
}
