import axios from 'axios'

export const API_URL = process.env.REACT_APP_API_URL

export function getFilters(queryParams) {
	return axios.get(
		`${API_URL}/filters?queryParams=${JSON.stringify(queryParams)}`,
	)
}

export function createMedia(data) {
	return axios.post(`${API_URL}/media`, data)
}
