import * as requestFromServer from './generalCrud'
import { generalSlice, callTypes } from './generalSlice'
import {
	casesSlice,
	callTypes as caseCallTypes,
} from '../../Case/_redux/cases/casesSlice'

const { actions } = generalSlice
const { mediaCreated } = casesSlice.actions

export const fetchFilters = (queryParams) => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	return requestFromServer
		.getFilters(queryParams)
		.then((response) => {
			const result = response.data.data
			dispatch(actions.filtersFetched({ filters: result }))
		})
		.catch((error) => {
			error.clientMessage = "Can't find data"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const createMedia = (media_data) => async (dispatch) => {
	if (media_data.type === 'case')
		dispatch(actions.startCall({ callType: caseCallTypes.action }))
	// else if(media_data.type === 'comment') dispatch(actions.caseCreated({ data }))
	// else if(media_data.type === 'solution') dispatch(actions.caseCreated({ data }))
	try {
		const response = await requestFromServer.createMedia(media_data)
		if (response.status !== 200) throw response
		const { data } = response.data
		if (media_data.type === 'case') dispatch(mediaCreated(data))
		// else if(media_data.type === 'comment') dispatch(actions.caseCreated({ data }))
		// else if(media_data.type === 'solution') dispatch(actions.caseCreated({ data }))
		// dispatch(actions.caseCreated({ data }))
	} catch ({ response }) {
		let error = response.data.message || "Can't create case"
		dispatch(actions.catchError({ error, callType: callTypes.action }))
	}
}
