import * as requestFromServer from './staffsCrud'
import { staffsSlice, callTypes } from './staffsSlice'

const { actions } = staffsSlice

export const fetchStaffs = (business_id) => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	return requestFromServer
		.findStaffs(business_id)
		.then((response) => {
			const { totalCount, entities } = response.data.data
			dispatch(actions.staffsFetched({ totalCount, entities }))
		})
		.catch((error) => {
			error.clientMessage = "Can't find staffs"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const fetchStaff = (id) => (dispatch) => {
	if (!id) {
		return dispatch(actions.staffFetched({ staffForEdit: undefined }))
	}

	dispatch(actions.startCall({ callType: callTypes.action }))
	return requestFromServer
		.getStaffById(id)
		.then((response) => {
			const { data } = response.data
			dispatch(actions.staffFetched({ staffForEdit: data }))
		})
		.catch((error) => {
			error.clientMessage = "Can't find staff"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const fetchOneStaff = (id) => (dispatch) => {
	if (!id) {
		return dispatch(actions.staffFetched({ staffForEdit: undefined }))
	}

	dispatch(actions.startCall({ callType: callTypes.action }))
	return requestFromServer
		.getOneStaff(id)
		.then((response) => {
			const { data } = response.data
			dispatch(actions.staffFetched({ staffForEdit: data }))
		})
		.catch((error) => {
			error.clientMessage = "Can't find staff"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const deleteStaff = (id) => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	return requestFromServer
		.deleteStaff(id)
		.then((response) => {
			dispatch(actions.staffDeleted({ id }))
		})
		.catch((error) => {
			error.clientMessage = "Can't delete staff"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const createStaff = (staffForCreation) => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	return requestFromServer
		.createStaff(staffForCreation)
		.then((response) => {
			const { data } = response.data
			dispatch(actions.staffCreated({ data }))
		})
		.catch(({ response }) => {
			let error = "Can't create staff"
			if (response.status === 400) error = response.data.errors[0].msg
			else error = response.data.message
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const updateStaff = (staff) => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	return requestFromServer
		.updateStaff(staff)
		.then(() => {
			dispatch(actions.staffUpdated({ staff }))
		})
		.catch((error) => {
			error.clientMessage = "Can't update staff"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const updateStaffsStatus = (ids, status) => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	return requestFromServer
		.updateStatusForStaffs(ids, status)
		.then(() => {
			dispatch(actions.staffsStatusUpdated({ ids, status }))
		})
		.catch((error) => {
			error.clientMessage = "Can't update staffs status"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const deleteStaffs = (ids) => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	return requestFromServer
		.deleteStaffs(ids)
		.then(() => {
			dispatch(actions.staffsDeleted({ ids }))
		})
		.catch((error) => {
			error.clientMessage = "Can't delete staffs"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}
