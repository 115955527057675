import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import 'jquery'
import ReactSummernote from 'react-summernote'
import 'react-summernote/dist/react-summernote.css' // import styles
import 'bootstrap'
import { Button } from 'react-bootstrap'
import { colors } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useDispatch } from 'react-redux'
import { createComment } from '../../../_redux/comments/commentsActions'
import store from '../../../../../../redux/store'
import SnackBarHelpers from '../../../../../../_metronic/_helpers/SnackBarHelpers'

const CommentSchema = Yup.object().shape({
	comment: Yup.string().required('Comment is required'),
})

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative',
	},
	buttonProgress: {
		color: colors.blue[500],
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}))

export function CommentBox({ case_id, authorizationData }) {
	const dispatch = useDispatch()
	const classes = useStyles()
	const [loading, setLoading] = useState(false)
	const [snackbar, setSnackbar] = useState()
	return (
		<>
			<Formik
				enableReinitialize={true}
				initialValues={{}}
				validationSchema={CommentSchema}
				onSubmit={(values) => {
					setLoading(true)
					values.case_id = case_id
					if (authorizationData) values.authorizationData = authorizationData
					dispatch(createComment(values)).then(() => {
						setLoading(false)
						let error_state = store.getState().comments.error
						if (error_state !== undefined && error_state !== null) {
							setSnackbar({
								status: true,
								vart: 'error',
								msg: error_state,
							})
						} else {
							setSnackbar({
								status: true,
								vart: 'success',
								msg: 'Comment added Successfully',
							})
							ReactSummernote.reset()
						}
					})
				}}
			>
				{({ handleSubmit, setFieldValue, values, errors }) => (
					<Form noValidate className='pb-1 w-100 w-md-550px w-lg-600px'>
						<div className='form-group'>
							{/* <label className='form-label mb-3'>Type Comment</label> */}
							<ReactSummernote
								value={values.description}
								options={{
									height: 150,
									width: 700,
									dialogsInBody: true,
									placeholder: 'Type your comment here...',
									toolbar: [
										['style', ['style']],
										['font', ['bold', 'underline', 'clear']],
										['fontname', ['fontname']],
										['para', ['ul', 'ol', 'paragraph']],
										// ['table', ['table']],
										['insert', ['link']],
										// ['insert', ['link', 'picture', 'video']],
										['view', ['fullscreen', 'codeview']],
									],
								}}
								onChange={(e) => setFieldValue('comment', e)}
							/>
							{errors.comment && (
								<div className='fv-plugins-message-container'>
									<div className='fv-help-block'>{errors.comment}</div>
								</div>
							)}
							<div className='row'>
								<div className={classes.root}>
									<div className={classes.wrapper}>
										<Button
											disabled={loading}
											variant='success'
											onClick={handleSubmit}
										>
											Submit
											{loading && (
												<span className='ml-1 mr-3 spinner spinner-white'></span>
											)}
										</Button>
									</div>
								</div>
							</div>
						</div>
					</Form>
				)}
			</Formik>
			{snackbar && (
				<SnackBarHelpers
					status={snackbar.status}
					vart={snackbar.vart}
					msg={snackbar.msg}
					setSnackbar={setSnackbar}
				/>
			)}
		</>
	)
}
