import axios from 'axios'

export const CASES_URL = process.env.REACT_APP_API_URL + '/cases'

// CREATE =>  POST: add a new case to the server
export function createCase(case_data) {
	if (case_data.business_id)
		return axios.post(process.env.REACT_APP_API_URL + '/createCase', case_data)
	else return axios.post(CASES_URL, case_data)
}

// READ
export function getAllCases() {
	return axios.get(CASES_URL)
}

export function getCaseById(caseId) {
	return axios.get(`${CASES_URL}/${caseId}`)
}

export function getOneCase(caseId) {
	return axios.get(`${CASES_URL}/${caseId}/getOne`)
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCases(queryParams = {}) {
	return axios.get(`${CASES_URL}?queryParams=${JSON.stringify(queryParams)}`)
}

// UPDATE => PUT: update the case on the server
export function updateCase(case_data) {
	return axios.put(`${CASES_URL}`, case_data)
}

// UPDATE => PUT: update the case on the server
export function updateCaseStatus(case_data) {
	return axios.put(`${CASES_URL}/status`, case_data)
}
// UPDATE => PUT: update the case on the server
export function updateCaseAssignment(case_data) {
	return axios.put(`${CASES_URL}/assignment`, case_data)
}

// UPDATE Status
export function updateStatusForCases(ids, status) {
	return axios.post(`${CASES_URL}/updateStatusForCases`, {
		ids,
		status,
	})
}

// DELETE => delete the case from the server
export function deleteCase(caseId) {
	return axios.delete(`${CASES_URL}/${caseId}`)
}

// DELETE Cases by ids
export function deleteCases(ids) {
	return axios.post(`${CASES_URL}/deleteCases`, { ids })
}

// READ ALL ADMIN FILTERS
export function generateFilter() {
	return axios.get(`${CASES_URL}/generateFilter`)
}
