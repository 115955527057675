import * as requestFromServer from './casesCrud'
import { casesSlice, callTypes } from './casesSlice'

const { actions } = casesSlice

export const getAllCases = () => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	try {
		const response = await requestFromServer.getAllCases()
		if (response.status !== 200) throw response
		const { totalCount, entities } = response.data.data
		dispatch(actions.casesFetched({ totalCount, entities }))
	} catch ({ response }) {
		let error = response.data.message || "Can't get cases"
		dispatch(actions.catchError({ error, callType: callTypes.list }))
	}
}

export const fetchCases = (queryParams) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	try {
		const response = await requestFromServer.findCases(queryParams)
		if (response.status !== 200) throw response
		const { totalCount, entities } = response.data.data
		dispatch(actions.casesFetched({ totalCount, entities }))
	} catch ({ response }) {
		let error = response?.data?.message || "Can't get cases"
		dispatch(actions.catchError({ error, callType: callTypes.list }))
	}
}

export const fetchCase = (id) => async (dispatch) => {
	if (!id) {
		return dispatch(actions.caseFetched({ caseForEdit: undefined }))
	}
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		const response = await requestFromServer.getCaseById(id)
		if (response.status !== 200) throw response
		const { data } = response.data
		dispatch(actions.caseFetched({ caseForEdit: data }))
	} catch ({ response }) {
		let error = response.data.message || "Can't find case"
		dispatch(actions.catchError({ error, callType: callTypes.list }))
	}
}

export const deleteCase = (id) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		const response = await requestFromServer.deleteCase(id)
		if (response.status !== 200) throw response
		dispatch(actions.caseDeleted({ id }))
	} catch ({ response }) {
		let error = response.data.message || "Can't delete case"
		dispatch(actions.catchError({ error, callType: callTypes.action }))
	}
}

export const createCase = (caseForCreation) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		const response = await requestFromServer.createCase(caseForCreation)
		if (response.status !== 200) throw response
		const { data } = response.data
		dispatch(actions.caseCreated({ data }))
	} catch ({ response }) {
		let error = response.data.message || "Can't create case"
		dispatch(actions.catchError({ error, callType: callTypes.action }))
	}
}

export const updateCase = (_case) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		const response = await requestFromServer.updateCase(_case)
		if (response.status !== 200) throw response
		dispatch(actions.caseUpdated({ _case }))
	} catch ({ response }) {
		let error = response.data.message || "Can't update case"
		dispatch(actions.catchError({ error, callType: callTypes.action }))
	}
}

export const updateCaseStatus = (case_data) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		const response = await requestFromServer.updateCaseStatus(case_data)
		if (response.status !== 200) throw response
		dispatch(actions.caseStatusUpdated({ status: case_data.status }))
	} catch ({ response }) {
		let error =
			response.status === 422
				? response.data.data[0].msg
				: response.data.message || "Can't update case"
		dispatch(actions.catchError({ error, callType: callTypes.action }))
	}
}
export const updateCaseAssignment = (case_data) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		const response = await requestFromServer.updateCaseAssignment(case_data)
		if (response.status !== 200) throw response
		dispatch(actions.caseAssignedToUpdated(case_data))
	} catch ({ response }) {
		let error =
			response.status === 422
				? response.data.data[0].msg
				: response.data.message || "Can't update case"
		dispatch(actions.catchError({ error, callType: callTypes.action }))
	}
}
