import React, { useState, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { ModalProgressBar } from '../../../../../../_metronic/_partials/controls'

export function CaseEditDialogHeader({ id }) {
	// Cases Redux state
	const { caseForEdit, actionsLoading } = useSelector(
		(state) => ({
			actionsLoading: state.cases.actionsLoading,
		}),
		shallowEqual,
	)

	const [title, setTitle] = useState('')
	// Title couting
	useEffect(() => {
		let _title = id ? '' : 'Create New Case'
		if (id) {
			_title = `Edit Case`
		}

		setTitle(_title)
		// eslint-disable-next-line
	}, [caseForEdit, actionsLoading])

	return (
		<>
			{actionsLoading && <ModalProgressBar />}
			<Modal.Header>
				<div className='container' style={{ padding: 0 }}>
					<Modal.Title id='example-modal-sizes-title-lg'>{title}</Modal.Title>
				</div>
			</Modal.Header>
		</>
	)
}
