import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import * as auth from '../app/modules/Auth/_redux/authRedux'
import { categorysSlice } from '../app/modules/Category/_redux/categorys/categorysSlice'
import { staffsSlice } from '../app/modules/User/_redux/staffs/staffsSlice'
import { customersSlice } from '../app/modules/User/_redux/customers/customersSlice'
import { generalSlice } from '../app/modules/General/_redux/generalSlice'
import { settingsSlice } from '../app/modules/Setting/_redux/settings/settingsSlice'
import { dashboardSlice } from '../app/pages/Dashboard/_redux/dashboardSlice'
import { casesSlice } from '../app/modules/Case/_redux/cases/casesSlice'
import { solutionsSlice } from '../app/modules/Solution/_redux/solutions/solutionsSlice'
import { commentsSlice } from '../app/modules/Case/_redux/comments/commentsSlice'

export const rootReducer = combineReducers({
	auth: auth.reducer,
	cases: casesSlice.reducer,
	solutions: solutionsSlice.reducer,
	comments: commentsSlice.reducer,
	categorys: categorysSlice.reducer,
	staffs: staffsSlice.reducer,
	customers: customersSlice.reducer,
	general: generalSlice.reducer,
	dashboard: dashboardSlice.reducer,
	settings: settingsSlice.reducer,
})

export function* rootSaga() {
	yield all([auth.saga()])
}
