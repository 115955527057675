import React, { Suspense, lazy } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout'
import { DashboardPage } from './pages/DashboardPage'
import { Setting } from './modules/Setting/SettingEditDialog'

const CasePage = lazy(() => import('./modules/Case/pages/CasePage'))
const SolutionPage = lazy(() => import('./modules/Solution/pages/SolutionPage'))
// const Setting = lazy(() => import('./modules/Setting/SettingEditDialog'))
// const ServicePage = lazy(() => import('./modules/Service/pages/ServicePage'))
const CategoryPage = lazy(() => import('./modules/Category/pages/CategoryPage'))
// const ServiceLocationPage = lazy(() =>
// 	import('./modules/ServiceLocation/pages/ServiceLocationPage'),
// )
// const UserPage = lazy(() => import('./modules/User/pages/UserPage'))
// const BookingPage = lazy(() => import('./modules/Booking/pages/BookingPage'))
// const CartPage = lazy(() => import('./modules/Cart/pages/CartPage'))
// const UserProfilepage = lazy(() =>
// 	import('./modules/UserProfile/UserProfilePage'),
// )

export default function BasePage() {
	// useEffect(() => {
	//   console.log('Base page');
	// }, []) // [] - is required if you need only one call
	// https://reactjs.org/docs/hooks-reference.html#useeffect

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				<Redirect exact from='/' to='/cases' />
				<ContentRoute path='/dashboard' component={DashboardPage} />
				<ContentRoute path='/settings' component={Setting} />
				<Route path='/cases' component={CasePage} />
				<Route path='/solutions' component={SolutionPage} />
				{/* <Route path='/services' component={ServicePage} /> */}
				<Route path='/categories' component={CategoryPage} />
				{/* <Route path='/servicelocations' component={ServiceLocationPage} /> */}
				{/* <Route path='/users' component={UserPage} /> */}
				{/* <Route path='/bookings' component={BookingPage} /> */}
				{/* <Route path='/carts' component={CartPage} />
				<Route path='/user-profile' component={UserProfilepage} /> */}
				<Redirect to='/' />
			</Switch>
		</Suspense>
	)
}
