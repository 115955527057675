import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../../../../_metronic/_helpers'
import { fetchCase, updateCaseStatus } from '../../../_redux/cases/casesActions'
import moment from 'moment'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Dropdown } from 'react-bootstrap'
import SnackBarHelpers from '../../../../../../_metronic/_helpers/SnackBarHelpers'
import store from '../../../../../../redux/store'
import { CommentBox } from './CommentBox'
import { getAllComments } from '../../../_redux/comments/commentsActions'
import { LoadingDialog } from '../../../../../../_metronic/_partials/controls'
import { CaseCloseModal } from './caseCloseModal'
import { AddFileModal } from './addFileModal'
import { createMedia } from '../../../../General/_redux/generalActions'
import { useParams, useHistory } from 'react-router'

export function CaseViewPage({ authorizationData }) {
	const history = useHistory()
	let { id } = useParams()
	const [loading, setLoading] = useState(false)
	const [snackbar, setSnackbar] = useState()
	const [closeCaseModal, setCloseCaseModal] = useState(false)
	const [addFileModal, setAddFileModal] = useState(false)
	const [fileType, setFileType] = useState()
	const dispatch = useDispatch()
	const { _case, comments, user, actionsLoading } = useSelector(
		(state) => ({
			_case: state.cases.caseForEdit,
			actionsLoading: state.cases.actionsLoading,
			comments: state.comments.entities,
			user: state.auth.user,
		}),
		shallowEqual
	)
	useEffect(() => {
		if (!authorizationData) {
			dispatch(fetchCase(id)).then(() => {
				let error_state = store.getState().cases.error
				if (error_state !== undefined && error_state !== null) {
					setSnackbar({
						status: true,
						vart: 'error',
						msg: error_state,
					})
					history.push('/cases')
				}
			})
			dispatch(getAllComments(id))
		}
		// eslint-disable-next-line
	}, [id, dispatch])
	function html_unescape(s) {
		var div = document.createElement('div')
		div.innerHTML = s
		return div.textContent || div.innerText // IE is different
	}
	const updateStatus = (status, reason, solution_id) => {
		if (status === 'closed') {
			setLoading(true)
			dispatch(updateCaseStatus({ id, status, reason, solution_id })).then(
				() => {
					setLoading(false)
					let error_state = store.getState().cases.error
					if (error_state !== undefined && error_state !== null) {
						setSnackbar({
							status: true,
							vart: 'error',
							msg: error_state,
						})
					} else {
						setSnackbar({
							status: true,
							vart: 'success',
							msg: 'Case Updated Successfully',
						})
					}
				}
			)
			setCloseCaseModal(false)
		} else {
			setLoading(true)
			dispatch(updateCaseStatus({ id, status })).then(() => {
				setLoading(false)
				let error_state = store.getState().cases.error
				if (error_state !== undefined && error_state !== null) {
					setSnackbar({
						status: true,
						vart: 'error',
						msg: error_state,
					})
				} else {
					setSnackbar({
						status: true,
						vart: 'success',
						msg: 'Case Updated Successfully',
					})
				}
			})
		}
	}
	const addFile = (values) => {
		setLoading(true)
		dispatch(createMedia({ ...values, id, type: fileType })).then(() => {
			setLoading(false)
			let error_state = store.getState()[fileType + 's'].error
			if (error_state !== undefined && error_state !== null) {
				setSnackbar({
					status: true,
					vart: 'error',
					msg: error_state,
				})
			} else {
				setSnackbar({
					status: true,
					vart: 'success',
					msg: 'File Added Successfully',
				})
				setAddFileModal(false)
			}
		})
	}
	return !_case || (id && _case.id !== Number(id)) ? (
		<LoadingDialog isLoading={actionsLoading} text='Loading ...' />
	) : (
		<>
			<div className='d-flex flex-column-fluid'>
				{/* begin::Container */}
				<div className='container'>
					{/* begin::Card */}
					<div className='card card-custom gutter-b'>
						<div className='card-body'>
							<div className='d-flex'>
								{/* begin: Pic */}
								<div className='flex-shrink-0 mr-7 mt-lg-0 mt-3'>
									<div className='symbol symbol-50 symbol-lg-120 d-none'>
										<img
											alt='Pic'
											src={toAbsoluteUrl('/media/project-logos/3.png')}
										/>
									</div>
									<div className='symbol symbol-50 symbol-lg-120 symbol-primary'>
										<span className='font-size-h1 symbol-label font-weight-boldest'>
											{_case.subject[0][0]}
										</span>
									</div>
								</div>
								{/* end: Pic */}
								{/* begin: Info */}
								<div className='flex-grow-1'>
									{/* begin: Title */}
									<div className='d-flex align-items-center justify-content-between flex-wrap'>
										<div className='mr-3'>
											{/* begin::Name */}
											<span className='d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3'>
												{_case.subject}
												{_case.status === 'closed' && (
													<i className='flaticon2-correct text-success icon-md ml-2'></i>
												)}
											</span>
											{/* end::Name */}
											{/* begin::Contacts */}
											<div className='d-flex flex-wrap my-2'>
												<span className='text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2'>
													<span className='svg-icon svg-icon-md svg-icon-gray-500 mr-1'>
														{/* begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-notification.svg */}
														<SVG
															src={toAbsoluteUrl(
																'/media/svg/icons/Code/Question-circle.svg'
															)}
														/>
													</span>
													Case No.: <b>{_case.case_no}</b>
												</span>
												<span className='text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2'>
													<span className='svg-icon svg-icon-md svg-icon-gray-500 mr-1'>
														{/* begin::Svg Icon | path:assets/media/svg/icons/General/Lock.svg */}
														<SVG
															src={toAbsoluteUrl(
																'/media/svg/icons/Code/Git4.svg'
															)}
														/>
													</span>
													Origin:{' '}
													<b className='text-uppercase'>{_case.origin}</b>
												</span>
											</div>
											{/* end::Contacts */}
											{/* begin::Contacts */}
											<div className='d-flex flex-wrap my-2'>
												<span className='text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2'>
													<span className='svg-icon svg-icon-md svg-icon-gray-500 mr-1'>
														{/* begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-notification.svg */}
														<SVG
															src={toAbsoluteUrl(
																'/media/svg/icons/Communication/Contact1.svg'
															)}
														/>
													</span>
													Contact Name:{' '}
													<b className='text-uppercase'>{_case.contact_name}</b>
												</span>
												<span className='text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2'>
													<span className='svg-icon svg-icon-md svg-icon-gray-500 mr-1'>
														{/* begin::Svg Icon | path:assets/media/svg/icons/General/Lock.svg */}
														<SVG
															src={toAbsoluteUrl(
																'/media/svg/icons/Communication/Mail.svg'
															)}
														/>
													</span>
													Contact Email: <b>{_case.contact_email}</b>
												</span>
											</div>
											{/* end::Contacts */}
											{/* begin::Contacts */}
											<div className='d-flex flex-wrap my-2'>
												<span className='text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2'>
													<span className='svg-icon svg-icon-md svg-icon-gray-500 mr-1'>
														{/* begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-notification.svg */}
														<SVG
															src={toAbsoluteUrl(
																'/media/svg/icons/Communication/Contact1.svg'
															)}
														/>
													</span>
													Assigned To: <b>{_case.assigned}</b>
												</span>
												<span className='text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2'>
													<span className='svg-icon svg-icon-md svg-icon-gray-500 mr-1'>
														{/* begin::Svg Icon | path:assets/media/svg/icons/General/Lock.svg */}
														<SVG
															src={toAbsoluteUrl(
																'/media/svg/icons/Map/Position.svg'
															)}
														/>
													</span>
													Department:{' '}
													<b className='text-uppercase'>{_case.department}</b>
												</span>
											</div>
											{/* end::Contacts */}
											{/* begin::Contacts */}
											<div className='d-flex flex-wrap my-2'>
												<span className='text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2'>
													<span className='svg-icon svg-icon-md svg-icon-gray-500 mr-1'>
														{/* begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-notification.svg */}
														<SVG
															src={toAbsoluteUrl(
																'/media/svg/icons/Map/Position.svg'
															)}
														/>
													</span>
													Type: <b className='text-uppercase'>{_case.type}</b>
												</span>
												<span className='text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2'>
													<span className='svg-icon svg-icon-md svg-icon-gray-500 mr-1'>
														{/* begin::Svg Icon | path:assets/media/svg/icons/General/Lock.svg */}
														<SVG
															src={toAbsoluteUrl(
																'/media/svg/icons/Map/Position.svg'
															)}
														/>
													</span>
													Request Type:{' '}
													<b className='text-uppercase'>{_case.request_type}</b>
												</span>
											</div>
											{/* end::Contacts */}
											{/* begin::Contacts */}
											<div className='d-flex flex-wrap my-2'>
												<span className='text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2'>
													{_case.updated_by && (
														<>
															Last modified by <b>{_case.updated_by}</b> on{' '}
															{moment(_case.updated_date).format(
																'DD MMM, YYYY'
															)}
														</>
													)}
												</span>
											</div>
											{/* end::Contacts */}
										</div>
										<div className='my-lg-0 my-1'>
											<span className='btn btn-sm btn-info font-weight-bolder text-uppercase mr-3'>
												{_case.priority}
											</span>
											{user ? (
												<OverlayTrigger
													placement='top'
													overlay={
														<Tooltip id='quick-actions-tooltip'>Status</Tooltip>
													}
												>
													<Dropdown
														className='dropdown-inline'
														drop='down'
														alignRight
													>
														<Dropdown.Toggle
															// className='btn btn-transparent-white btn-sm font-weight-bolder dropdown-toggle px-5'
															className={`btn btn-sm btn-light-${
																_case.status === 'closed'
																	? 'success'
																	: _case.status === 'new'
																	? 'primary'
																	: _case.status === 'hold'
																	? 'warning'
																	: _case.status === 'working'
																	? 'success'
																	: _case.status === 're-opened'
																	? 'primary'
																	: _case.status === 'escalated'
																	? 'danger'
																	: ''
															} font-weight-bolder text-uppercase`}
															variant='transparent'
															id='dropdown-toggle-top'
														>
															{loading ? (
																<div className='overlay-layer bg-transparent'>
																	<div className='spinner spinner-sm spinner-success' />
																</div>
															) : (
																_case.status
															)}
														</Dropdown.Toggle>
														<Dropdown.Menu className='dropdown-menu dropdown-menu-sm dropdown-menu-right'>
															{/*begin::Navigation*/}
															<ul className='navi navi-hover'>
																<li className='navi-header pb-1'>
																	<span className='text-primary text-uppercase font-weight-bold font-size-sm'>
																		Choose Status:
																	</span>
																</li>
																{!['new', 'closed'].includes(_case.status) && (
																	<li className='navi-item'>
																		<span
																			onClick={() => updateStatus('new')}
																			className='navi-link'
																		>
																			<span className='navi-icon'>
																				<i className='flaticon2-shopping-cart-1'></i>
																			</span>
																			<span className='navi-text'>New</span>
																		</span>
																	</li>
																)}
																{!['working', 'closed'].includes(
																	_case.status
																) && (
																	<li className='navi-item'>
																		<span
																			onClick={() => updateStatus('working')}
																			className='navi-link'
																		>
																			<span className='navi-icon'>
																				<i className='flaticon2-shopping-cart-1'></i>
																			</span>
																			<span className='navi-text'>Working</span>
																		</span>
																	</li>
																)}
																{!['hold', 'closed'].includes(_case.status) && (
																	<li
																		onClick={() => updateStatus('hold')}
																		className='navi-item'
																	>
																		<span className='navi-link'>
																			<span className='navi-icon'>
																				<i className='flaticon2-shopping-cart-1'></i>
																			</span>
																			<span className='navi-text'>Hold</span>
																		</span>
																	</li>
																)}
																{!['escalated', 'closed'].includes(
																	_case.status
																) && (
																	<li className='navi-item'>
																		<span
																			onClick={() => updateStatus('escalated')}
																			className='navi-link'
																		>
																			<span className='navi-icon'>
																				<i className='flaticon2-shopping-cart-1'></i>
																			</span>
																			<span className='navi-text'>
																				Escalated
																			</span>
																		</span>
																	</li>
																)}
																{_case.status !== 'closed' && (
																	<li className='navi-item'>
																		<span
																			onClick={() => setCloseCaseModal(true)}
																			className='navi-link'
																		>
																			<span className='navi-icon'>
																				<i className='flaticon2-shopping-cart-1'></i>
																			</span>
																			<span className='navi-text'>Close</span>
																		</span>
																	</li>
																)}
																{_case.status === 'closed' &&
																	_case.status !== 're-opened' && (
																		<li className='navi-item'>
																			<span
																				onClick={() =>
																					updateStatus('re-opened')
																				}
																				className='navi-link'
																			>
																				<span className='navi-icon'>
																					<i className='flaticon2-shopping-cart-1'></i>
																				</span>
																				<span className='navi-text'>
																					Re-Open
																				</span>
																			</span>
																		</li>
																	)}
															</ul>
															{/*end::Navigation*/}
														</Dropdown.Menu>
													</Dropdown>
												</OverlayTrigger>
											) : (
												<span
													className={`btn btn-sm btn-light-${
														_case.status === 'closed'
															? 'success'
															: _case.status === 'new'
															? 'primary'
															: _case.status === 'hold'
															? 'warning'
															: _case.status === 'working'
															? 'success'
															: _case.status === 're-opened'
															? 'primary'
															: _case.status === 'escalated'
															? 'danger'
															: ''
													} font-weight-bolder text-uppercase`}
												>
													{_case.status}
												</span>
											)}
										</div>
									</div>
									{/* end: Title */}
									{/* begin: Content */}
									<div className='d-flex align-items-center flex-wrap justify-content-between'>
										<div className='flex-grow-1 font-weight-bold text-dark-50 py-5 py-lg-2 mr-5'>
											{html_unescape(`${_case.description}`)}
										</div>
										{/* <div className='d-flex flex-wrap align-items-center py-2'>
										<div className='d-flex align-items-center mr-10'>
											<div className='mr-6'>
												<div className='font-weight-bold mb-2'>
													Created Date
												</div>
												<span className='btn btn-sm btn-text btn-light-primary text-uppercase font-weight-bold'>
													07 May, 2020
												</span>
											</div>
											<div className=''>
												<div className='font-weight-bold mb-2'>Due Date</div>
												<span className='btn btn-sm btn-text btn-light-danger text-uppercase font-weight-bold'>
													10 June, 2021
												</span>
											</div>
										</div>
										<div className='flex-grow-1 flex-shrink-0 w-150px w-xl-300px mt-4 mt-sm-0'>
											<span className='font-weight-bold'>Progress</span>
											<div className='progress progress-xs mt-2 mb-2'>
												<div
													className='progress-bar bg-success'
													role='progressbar'
													style={{
														width: '63%',
														ariaValuenow: '50',
														ariaValuemin: '0',
														ariaValuemax: '100',
													}}
												></div>
											</div>
											<span className='font-weight-bolder text-dark'>78%</span>
										</div>
									</div> */}
									</div>
									{/* end: Content */}
								</div>
								{/* end: Info */}
							</div>
							<div className='separator separator-solid my-7'></div>
							{/* begin: Items */}
							<div className='d-flex align-items-center flex-wrap'>
								{/* begin: Item */}
								<div className='d-flex align-items-center flex-lg-fill mr-5 my-1'>
									<span className='mr-4'>
										<i className='flaticon-calendar-with-a-clock-time-tools icon-2x text-muted font-weight-bold'></i>
									</span>
									<div className='d-flex flex-column text-dark-75'>
										<span className='font-weight-bolder font-size-sm'>
											Created Date
										</span>
										<span className='font-weight-bolder'>
											<span className='text-dark-50 font-weight-bold'>
												{moment(_case.created_date).format('DD MMM, YYYY')}
											</span>
										</span>
									</div>
								</div>
								{/* end: Item */}
								{/* begin: Item */}
								<div className='d-flex align-items-center flex-lg-fill mr-5 my-1'>
									<span className='mr-4'>
										<i className='flaticon2-user icon-2x text-muted font-weight-bold'></i>
									</span>
									<div className='d-flex flex-column text-dark-75'>
										<span className='font-weight-bolder font-size-sm'>
											Created By
										</span>
										<span className='font-weight-bolder'>
											<span className='text-dark-50 font-weight-bold'>
												{_case.created_by
													? _case.created_by.length > 20
														? _case.created_by.substring(0, 20) + '...'
														: _case.created_by
													: _case.contact_name.length > 20
													? _case.contact_name.substring(0, 20) + '...'
													: _case.contact_name}
											</span>
										</span>
									</div>
								</div>
								{/* end: Item */}
								{/* begin: Item */}
								<div className='d-flex align-items-center flex-lg-fill mr-5 my-1'>
									<span className='mr-4'>
										<i className='flaticon-pie-chart icon-2x text-muted font-weight-bold'></i>
									</span>
									<div className='d-flex flex-column text-dark-75'>
										<span className='font-weight-bolder font-size-sm'>
											Response Status
										</span>
										<span className='font-weight-bolder'>
											<span className='text-dark-50 font-weight-bold'>
												{_case.response_status}
											</span>
										</span>
									</div>
								</div>
								{/* end: Item */}
								{/* begin: Item */}
								<div className='d-flex align-items-center flex-lg-fill mr-5 my-1'>
									<span className='mr-4'>
										<i className='flaticon2-paperplane icon-2x text-muted font-weight-bold'></i>
									</span>
									<div className='d-flex flex-column flex-lg-fill'>
										<span className='text-dark-75 font-weight-bolder font-size-sm'>
											Origin
										</span>
										<span className='text-primary font-weight-bolder'>
											{_case.origin}
										</span>
									</div>
								</div>
								{/* end: Item */}
								{/* begin: Item */}
								{/* <div className='d-flex align-items-center flex-lg-fill mr-5 my-1'>
									<span className='mr-4'>
										<i className='flaticon-chat-1 icon-2x text-muted font-weight-bold'></i>
									</span>
									<div className='d-flex flex-column'>
										<span className='text-dark-75 font-weight-bolder font-size-sm'>
											SLA Violation
										</span>
										<span className='text-primary font-weight-bolder'>
											{_case.sla_violation}
										</span>
									</div>
								</div> */}
								{/* end: Item */}
								{/* begin: Item */}
								{/* <div className='d-flex align-items-center flex-lg-fill mr-5 my-1'>
									<span className='mr-4'>
										<i className='flaticon-confetti icon-2x text-muted font-weight-bold'></i>
									</span>
									<div className='d-flex flex-column text-dark-75'>
										<span className='font-weight-bolder font-size-sm'>
											Rating
										</span>
										<span className='font-weight-bolder font-size-h5'>
											<span className='text-dark-50 font-weight-bold'>$</span>
											164,700
										</span>
									</div>
								</div> */}
								{/* end: Item */}
							</div>
							{/* begin: Items */}
						</div>
					</div>
					{/* end::Card */}
					{/* begin::Card */}
					<div className='card card-custom'>
						{/* begin::Header */}
						<div className='card-header card-header-tabs-line'>
							<div className='card-toolbar'>
								<ul
									className='nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-tabs-bold nav-tabs-line-3x'
									role='tablist'
								>
									<li className='nav-item mr-3'>
										<a
											className='nav-link active'
											data-toggle='tab'
											href='#comment'
										>
											<span className='nav-icon mr-2'>
												<span className='svg-icon mr-3'>
													{/* begin::Svg Icon | path:assets/media/svg/icons/Communication/Chat-check.svg */}
													<SVG
														src={toAbsoluteUrl(
															'/media/svg/icons/Communication/Chat-check.svg'
														)}
													/>
												</span>
											</span>
											<span className='nav-text font-weight-bold'>Comment</span>
										</a>
									</li>
									<li className='nav-item mr-3'>
										<a className='nav-link' data-toggle='tab' href='#files'>
											<span className='nav-icon mr-2'>
												<span className='svg-icon mr-3'>
													{/* begin::Svg Icon | path:assets/media/svg/icons/Communication/Chat-check.svg */}
													<SVG
														src={toAbsoluteUrl(
															'/media/svg/icons/Files/Folder-solid.svg'
														)}
													/>
												</span>
											</span>
											<span className='nav-text font-weight-bold'>Files</span>
										</a>
									</li>
								</ul>
							</div>
						</div>
						{/* end::Header */}
						{/* begin::Body */}
						<div className='card-body'>
							<div className='tab-content pt-5'>
								<div className='tab-pane active' id='comment' role='tabpanel'>
									<div className='container'>
										<CommentBox
											case_id={_case.id}
											authorizationData={
												authorizationData ? authorizationData : undefined
											}
										/>
										<div className='separator separator-dashed my-2'></div>
										{/* begin::Timeline */}
										<div className='timeline timeline-3'>
											<div className='timeline-items'>
												{comments.map(
													(comment) =>
														comment.case_id === Number(_case.id) && (
															<div
																key={comment.id}
																className='timeline-item timeline-item-right'
															>
																<div className='timeline-media'>
																	<i className='flaticon2-layers text-warning'></i>
																</div>
																<div className='timeline-content'>
																	<div className='d-flex align-items-center justify-content-between mb-3'>
																		<div className='mr-2'>
																			<span className='text-dark-75 text-hover-primary font-weight-bold'>
																				{comment.created_by
																					? comment.created_by
																					: _case.contact_name}
																			</span>
																			<span className='text-muted ml-2'>
																				{moment(comment.created_at).calendar()}
																			</span>
																			{/* <span className='label label-light-success font-weight-bolder label-inline ml-2'>
																		new
																	</span> */}
																		</div>
																	</div>
																	<p className='p-0'>
																		{html_unescape(comment.comment)}
																	</p>
																</div>
															</div>
														)
												)}
											</div>
										</div>
										{/* end::Timeline */}
									</div>
								</div>
								<div className='tab-pane' id='files' role='tabpanel'>
									<div className='row'>
										{_case.files &&
											_case.files.map((file, index) => (
												<a
													href={file.file_url}
													target='_blank'
													rel='noreferrer'
													key={index}
													className='col-xl-3 col-lg-6 col-md-6 col-sm-6'
												>
													{/* begin::Card */}
													<div className='card card-custom gutter-b card-stretch'>
														<div className='card-body'>
															<div className='d-flex flex-column align-items-center'>
																{/* begin: Icon */}
																<img
																	alt=''
																	className='max-h-65px'
																	src={toAbsoluteUrl(
																		'/media/svg/files/pdf.svg'
																	)}
																/>
																{/* end: Icon */}
																{/* begin: Tite */}
																<div className='font-weight-bold mt-15 font-size-lg'>
																	{file.file_name}
																</div>
																{/* end: Tite */}
															</div>
														</div>
													</div>
													{/* end:: Card */}
												</a>
											))}
										<div className='col-xl-3 col-lg-6 col-md-6 col-sm-6'>
											{/* begin::Card */}
											<div className='card card-custom gutter-b'>
												<div className='card-body'>
													<div
														onClick={() => {
															setAddFileModal(true)
															setFileType('case')
														}}
														className='d-flex flex-column align-items-center cursor-pointer'
													>
														{/* begin: Icon */}
														<img
															alt=''
															className='max-h-65px'
															src={toAbsoluteUrl(
																'/media/svg/icons/Navigation/Plus.svg'
															)}
														/>
														{/* end: Icon */}
														{/* begin: Tite */}
														<span className='text-dark-75 font-weight-bold mt-15 font-size-lg'>
															Add File
														</span>
														{/* end: Tite */}
													</div>
												</div>
											</div>
											{/* end:: Card */}
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* end::Body */}
					</div>
					{/* end::Card */}
				</div>
				{/* end::Container */}
			</div>
			{snackbar && (
				<SnackBarHelpers
					status={snackbar.status}
					vart={snackbar.vart}
					msg={snackbar.msg}
					setSnackbar={setSnackbar}
				/>
			)}
			<CaseCloseModal
				closeCaseModal={closeCaseModal}
				setCloseCaseModal={setCloseCaseModal}
				updateStatus={updateStatus}
			/>
			<AddFileModal
				addFileModal={addFileModal}
				setAddFileModal={setAddFileModal}
				addFile={addFile}
				loading={loading}
				setLoading={setLoading}
			/>
		</>
	)
}
