export const SolutionStatusCssClasses = ['primary', 'success', '']
export const SolutionStatusTitles = ['draft', 'published', '']
export const SolutionPriorityCssClasses = ['primary', 'warning', 'danger', '']
export const SolutionPriorityTitles = ['low', 'medium', 'high', '']
export const defaultSorted = [{ dataField: 'id', order: 'asc' }]
export const sizePerPageList = [
	{ text: '3', value: 3 },
	{ text: '5', value: 5 },
	{ text: '10', value: 10 },
]
export const initialFilter = {
	filter: {
		// solution_number: '',
	},
	sortOrder: 'asc', // asc||desc
	sortField: 'id',
	pageNumber: 1,
	pageSize: 10,
}
